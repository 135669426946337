import axios from '@/plugins/axios'
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            tKey: 'dashboard.',
            loading: false,    
            kpis: [],        
            charts: [],
        }
    },
    computed: {
        ...mapGetters('dashboard', ['objects'])
    },
    methods: {
        ...mapActions('dashboard', ['setObjects']),
        async refreshAll() {
            Promise.all([await this.refreshObjectsData(), await this.refreshKPIData()])
        },
        async refreshObjectsData() {
            this.loading = 'allObjects'
            Promise.all([
                await this.getObjects(),               
            ])
                .then((res) => {
                    this.setObjects(res[0].data)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async refreshKPIData() {
            this.loading = true
            Promise.all([
                await this.getFlagsKPI(),
                await this.getServicesTypesKPI(),
                await this.getHeatingSystemsKPI(),
                await this.getBoilerSystemsKPI(),
                await this.getConstructionYearsSerie(),
                //await this.getaccommodationDetails(),
            ])            
                .then((res) => {
                    
                    const proportionKPI = {
                        label: 'proportion',
                        icon: 'users',
                        values: this.setProportionKPI(this.objects),                        
                    }
                    proportionKPI.chart = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: proportionKPI.values.map((v) => this.translateLabels(proportionKPI.label, v.type)),
                        },
                        series: proportionKPI.values.map((v) => v.count),
                    }
                    const flagsKPI = {
                        label: 'qualityFlags',
                        icon: 'flag',
                        values: res[0].data,
                    }
                    flagsKPI.chart = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: flagsKPI.values.map((v) => this.translateLabels(flagsKPI.label, v.type)),
                        },
                        series: flagsKPI.values.map((v) => v.count),
                    }
                    const servicesTypesKPI = {
                        label: 'servicesTypes',
                        icon: 'concierge-bell',
                        values: res[1].data,
                        noTranslate: true,
                    }
                    servicesTypesKPI.chart = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(servicesTypesKPI.values.map((v) => v.count), servicesTypesKPI.values.map((v) => v.type)),
                        },
                        series: this.combineSmallerSeries(servicesTypesKPI.values.map((v) => v.count)),
                    }

             
/*
                    const accommodationsKPI = {
                        label: 'heatingSystems',
                        icon: 'hotel',
                        values: res[5].data,
                    }
                    accommodationsKPI.chartSystem = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(accommodationsKPI.values.systems.map((v) => v.count), accommodationsKPI.values.systems.map((v) => this.translateLabels(accommodationsKPI.label, v.type)), 5),
                        },
                        series: this.combineSmallerSeries(accommodationsKPI.values.systems.map((v) => v.count), 5),
                    }
                    accommodationsKPI.chartSource = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(accommodationsKPI.values.sources.map((v) => v.count), accommodationsKPI.values.sources.map((v) => this.translateLabels('sources', v.type)), 5),
                        },
                        series: this.combineSmallerSeries(accommodationsKPI.values.sources.map((v) => v.count), 5),
                    }


*/







                    const heatingSystemsKPI = {
                        label: 'heatingSystems',
                        icon: 'fire',
                        values: res[2].data,
                    }
                    heatingSystemsKPI.chartSystem = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(heatingSystemsKPI.values.systems.map((v) => v.count), heatingSystemsKPI.values.systems.map((v) => this.translateLabels(heatingSystemsKPI.label, v.type)), 5),
                        },
                        series: this.combineSmallerSeries(heatingSystemsKPI.values.systems.map((v) => v.count), 5),
                    }
                    heatingSystemsKPI.chartSource = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(heatingSystemsKPI.values.sources.map((v) => v.count), heatingSystemsKPI.values.sources.map((v) => this.translateLabels('sources', v.type)), 5),
                        },
                        series: this.combineSmallerSeries(heatingSystemsKPI.values.sources.map((v) => v.count), 5),
                    }
                    const boilerSystemsKPI = {
                        label: 'boilerSystems',
                        icon: 'tint',
                        values: res[3].data,
                    }
                    boilerSystemsKPI.chartSystem = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(boilerSystemsKPI.values.systems.map((v) => v.count), boilerSystemsKPI.values.systems.map((v) => this.translateLabels(boilerSystemsKPI.label, v.type)), 5),
                        },
                        series: this.combineSmallerSeries(boilerSystemsKPI.values.systems.map((v) => v.count), 5),
                    }
                    boilerSystemsKPI.chartSource = {
                        options: {
                            chart: {
                                type: 'pie',
                            },
                            legend: {
                                position: "bottom",
                            },
                            labels: this.combineSmallerLabels(boilerSystemsKPI.values.sources.map((v) => v.count), boilerSystemsKPI.values.sources.map((v) => this.translateLabels('sources', v.type)), 5),
                        },
                        series: this.combineSmallerSeries(boilerSystemsKPI.values.sources.map((v) => v.count), 5),
                    }                    
                    this.setKPIs( servicesTypesKPI, heatingSystemsKPI, boilerSystemsKPI, flagsKPI)





                    const currentYear = new Date().getFullYear()
                    const constructionYearsChart = {
                        label: 'constructionYears',
                        icon: 'calendar',
                    }
                
                    var constructionYears = new Array();
                    var constructionYearsCount = new Array();

                    res[4].data.forEach(element => {
                        constructionYears.push(element.interval)
                        constructionYearsCount.push(element.count)
                    });

                    constructionYearsChart.chart = {
                        options: {
                            chart: {
                                type: 'bar',
                                height: 400,
                            },
                            legend: {
                                position: "bottom",
                            },
                            xaxis: {
                                categories: constructionYears,
                                labels: {
                                    hideOverlappingLabels: true,
                                },
                            },
                        }, 
                        series: [
                            {
                                name: this.$t('chart.constructionYears.label'),
                                data: constructionYearsCount,
                            },
                        ],                       
                    }
                    this.setCharts(constructionYearsChart)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async getSections() {
            await axios.post('api/AHV/getSections').then((res) => {
                var options = new Array();
                res.data
                .sort((a, b) => a.section_name.localeCompare(b.section_name))
                .forEach(function (item) {
                    options.push({  value: item.section_id, text: item.section_name });
                  });

                  this.sections.options = [
                    { text: "Toutes les sections", value: 0 },
                  ].concat(options);

            })
        },

        async getObjects() {
            return await axios.post('api/AHV/getAll')
        },
        async getFlagsKPI() {
            return await axios.post('api/AHV/getFlagsKPI', {section: this.sections.default})
        },
        async getServicesTypesKPI() {
            return await axios.post('api/AHV/getServicesTypesKPI', {section: this.sections.default})
        },
        async getHeatingSystemsKPI() {
            return await axios.post('api/AHV/getHeatingSystemsKPI', {section: this.sections.default})
        },
        async getBoilerSystemsKPI() {
            return await axios.post('api/AHV/getBoilerSystemsKPI', {section: this.sections.default})
        },
        async getConstructionYearsSerie() {
            return await axios.post('api/AHV/getConstructionYearsSerie', {section: this.sections.default})
        },
        async getaccommodationDetails() {
            await axios.post('api/AHV/getAccommodationCapacity', {section: this.sections.default}
            ).then((res) => {
                console.log(res.data)
            })
        },
        setProportionKPI(objects) {
            return [
                {
                    type: 'objects',
                    count: objects.filter(obj => obj.idMember == null).length,
                },
                {
                    type: 'members',
                    count: objects.filter(obj => obj.idMember != null).length,
                },
            ]
        },
        setKPIs(...kpis) {
            this.kpis = []
            for(const k of kpis)
                this.kpis.push(k)    
        },
        setCharts(...charts) {
            this.charts = []
            for(const c of charts)
                this.charts.push(c)
        },
        combineSmallerSeries(series, top = 3) {
            let processedSeries = series.slice(0, top)
            const otherValues = series.slice(top)
            if (otherValues.length > 0) {
                const sumOfOtherValues = otherValues.reduce((acc, value) => acc + value, 0)
                processedSeries.push(sumOfOtherValues)
            }
            return processedSeries
        },
        combineSmallerLabels(series, labels, top = 3) {
            const processedLabels = labels.slice(0, top)
            if (!series.slice(top).length) return labels
            processedLabels.push(this.$t('utils.others'))      
            return processedLabels
        },
        translateLabels(label, type) {
            return this.$t(`kpi.${label}.${type}`)
        },
    },
}