<template>
  <div id="dashboard">
    <loading v-if="loading" :loading="loading" />
    <div v-else>
      <div id="viewNavigator">
        <span class="navigator-btn" @click="showMap = true" :class="showMap && 'active'" v-html="$t(`${tKey}showMap`)" />
        <span class="navigator-btn" @click="showMap = false" :class="!showMap && 'active'"
          v-html="$t(`${tKey}showIndicators`)" />
      </div>
      <div v-if="showMap">
        <search-bar v-if="isLoggedIn" class="w-100" :placeholderKey="`${tKey}search`"
          @search="(query) => searchQuery = query">
          <div class="d-flex align-items-center h-100 bg-secondary text-white">
            <b-checkbox class="my-auto mx-2" v-model="noLocationOnly">
              <span v-html="$t(`${tKey}noLocationOnly`)" />
            </b-checkbox>
          </div>
        </search-bar>
        <ItemsList v-if="isLoggedIn" :tKey="tKey" :items="searchedObjects" />
        <l-map v-if="objects && objects.length" ref="myMap" style="height: 700px;" :center="center" :zoom="zoom"
          :options="mapOptions" @update:center="centerUpdate" @update:zoom="zoomUpdate" @update:bounds="boundsUpdate">
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-control class="d-flex flex-column" position="topright">
            <div class="bg-white p-2 m-1 shadow">
              <div class="d-flex align-items-center mb-2">
                <svg width="14" height="14">
                  <circle cx="7" cy="7" r="6" :fill="colors.members" :stroke="colors.members" stroke-width="2"
                    style="fill-opacity: .5;" />
                </svg>
                <span class="ml-2" v-html="$t(`${tKey}legend.member`)" />
              </div>
              <div class="d-flex align-items-center">
                <svg width="14" height="14">
                  <circle cx="7" cy="7" r="6" :fill="colors.notMembers" :stroke="colors.notMembers" stroke-width="2"
                    style="fill-opacity: .5;" />
                </svg>
                <span class="ml-2" v-html="$t(`${tKey}legend.notMember`)" />
              </div>
            </div>
            <div class="bg-white p-2 m-1 shadow">
              <h6 v-html="$t(`${tKey}legend.header`)" />
              <div class="d-flex align-items-center flex-column">
                <b-row class="w-100 px-2 align-items-center" no-gutters>
                  <b-col sm="3">
                    <font-awesome-icon icon="home" size="2x" />
                  </b-col>
                  <b-col class="d-flex flex-column">
                    <span v-html="$t(`${tKey}legend.totalEstablishments`)" />
                    <span class="font-weight-bold" v-html="longLatObjects.length" />
                  </b-col>
                </b-row>
                <b-row class="w-100 px-2 align-items-center" no-gutters>
                  <b-col sm="3">
                    <ahv-logo :height="27" />
                  </b-col>
                  <b-col class="d-flex flex-column">
                    <span v-html="$t(`${tKey}legend.totalMembers`)" />
                    <span class="font-weight-bold" v-html="nbMembers" />
                  </b-col>
                </b-row>
                <b-row class="w-100 px-2 align-items-center" no-gutters>
                  <b-col sm="3">
                    <font-awesome-layers>
                      <font-awesome-icon icon="map-marker-alt" size="2x" color="lightgrey" transform="right-4" />
                      <font-awesome-icon icon="slash" size="2x" transform="shrink-2" />
                    </font-awesome-layers>
                  </b-col>
                  <b-col class="d-flex flex-column">
                    <span v-html="$t(`${tKey}legend.missingEstablishments`)" />
                    <span class="font-weight-bold" v-html="searchedObjects.length - longLatObjects.length" />
                  </b-col>
                </b-row>
                <div class="mt-2 d-flex flex-column">
                  <b-button variant="info" size="sm" @click="refreshObjectsData">
                    <font-awesome-icon class="mr-2" icon="sync-alt" />
                    <span v-html="$t(`${tKey}refresh`)" />
                  </b-button>
                  <span class="font-italic" v-html="$t(`${tKey}refreshInfo`)" />
                </div>
              </div>
            </div>
          </l-control>
          <l-circle-marker v-for="obj in longLatObjects" :key="obj.contractId" :radius="6" :lat-lng="[obj.lat, obj.long]"
            :color="obj.idMember ? colors.members : colors.notMembers"
            :fill-color="obj.idMember ? colors.members : colors.notMembers">
            <l-popup class="custom-popup">
              <div v-if="obj.qualityFlag !== 'ok'" class="flag-container p-1 mb-2"
                :style="`background-color: ${getFlagColor(obj.qualityFlag)}`" v-html="$t(`flags.${obj.qualityFlag}`)" />
              <h6 class="d-flex justify-content-between font-weight-bold align-items-center pb-2">
                <span v-html="obj.name" />
                <ahv-logo v-if="obj.idMember" :height="20" />
              </h6>
              <div class="border-top pt-2">
                <p v-html="`${obj.address}${checkIfBothAddressAndCity(obj)}<b>${obj.city}</b>`" />
              </div>
              <b-link v-if="isLoggedIn"
                :to="$i18nRoute({ name: obj.idMember ? 'MemberDetails' : 'EstablishmentDetails', params: obj.idMember ? { idMember: obj.idMember } : { id: obj.contractId } })">
                <span v-html="$t(tKey + 'display')" />
                <font-awesome-icon class="ml-2" icon="arrow-right" />
              </b-link>
            </l-popup>
          </l-circle-marker>
        </l-map>
      </div>
      <div v-else>
        <div class="position-fixed" style="top: 130px; right: 70px;">
      <b-form-select v-model="this.sections.default" :options="this.sections.options" class="form-control-sm" @change="changeSection"></b-form-select>
        </div>
        <b-row id="kpis" cols="1">
           <b-col sm="12" class="pt-4" v-for="kpi in kpis.filter(k => k.values.length)" :key="kpi.label">
            <b-card class="h-100" no-body>
              <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center"
                header-tag="h5">
                <span v-html="$t(`kpi.${kpi.label}.header`)" />
                <font-awesome-icon :icon="kpi.icon" />
              </b-card-header>
              <b-card-body>
                <div class="my-2" v-html="$t(`kpi.${kpi.label}.description`)" />
                <b-row cols="2" no-gutters>
                  <b-col v-if="kpi.values.length">
                    <div class="value-container my-2" v-for="val in kpi.values" :key="val.type">
                      <div class="font-weight-bold kpi-label-header"
                        v-html="kpi.noTranslate ? val.type : translateLabels(kpi.label, val.type)" />
                      <div>
                        <span class="font-weight-bold" v-html="val.count" />
                        <span class="small ml-1" v-html="$tc('utils.establishment', val.count)" />
                      </div>
                    </div>
                  </b-col>
                  <b-col md="12" lg="4">
                    <apexchart v-if="kpi.chart" type="pie" :options="kpi.chart.options" :series="kpi.chart.series" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col> 
          <b-col sm="12" class="pt-4" v-for="chart in charts" :key="chart.label">
            <b-card class="h-100" no-body>
              <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center"
                header-tag="h5">
                <span v-html="$t(`chart.${chart.label}.header`)" />
                <font-awesome-icon :icon="chart.icon" />
              </b-card-header>
              <b-card-body>
                <div class="my-2" v-html="$t(`chart.${chart.label}.description`)" />
                <apexchart v-if="chart.chart" type="bar" :options="chart.chart.options" :series="chart.chart.series" />
              </b-card-body>
            </b-card>
          </b-col>
        <!--   <b-col>
            <b-card no-body>
              <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center" header-tag="h5">
               <b-card-body>
                <span v-html="efadfa" />
                <font-awesome-icon icon="concierge-bell" />
               </b-card-body>

              </b-card-header>
            </b-card> 
          </b-col>-->
          <b-col sm="12" class="pt-4" v-for="kpi in kpis.filter(k => !k.values.length)" :key="kpi.label">
            <b-card no-body>
              <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center"
                header-tag="h5">
                <span v-html="$t(`kpi.${kpi.label}.header`)" />
                <font-awesome-icon :icon="kpi.icon" />
              </b-card-header>
              <b-card-body>
                <div v-html="$t(`kpi.${kpi.label}.description`)" />
                <b-row class="pb-2 border-bottom border-grey" no-gutters>
                  <b-col>
                    <b-row cols="2" v-if="kpi.values.systems.length">
                      <b-col class="value-container my-2" v-for="val in kpi.values.systems" :key="val.type">
                        <div class="font-weight-bold kpi-label-header"
                          v-html="kpi.noTranslate ? val.type : translateLabels(kpi.label, val.type)" />
                        <div>
                          <span class="font-weight-bold" v-html="val.count" />
                          <span class="small ml-1" v-html="$tc('utils.establishment', val.count)" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="12" lg="4">
                    <apexchart v-if="kpi.chartSystem" type="pie" :options="kpi.chartSystem.options"
                      :series="kpi.chartSystem.series" />
                  </b-col>
                </b-row>
                <b-row no-gutters>
                  <b-col>
                    <b-row cols="2" v-if="kpi.values.sources.length">
                      <b-col class="value-container my-2" v-for="val in kpi.values.sources" :key="val.type">
                        <div class="font-weight-bold kpi-label-header"
                          v-html="kpi.noTranslate ? val.type : translateLabels('sources', val.type)" />
                        <div>
                          <span class="font-weight-bold" v-html="val.count" />
                          <span class="small ml-1" v-html="$tc('utils.establishment', val.count)" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="12" lg="4">
                    <apexchart v-if="kpi.chartSource" type="pie" :options="kpi.chartSource.options"
                      :series="kpi.chartSource.series" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
   <!--        <b-col sm="12" class="pt-4">
            <b-card class="h-100" no-body>
              <b-card-header class="bg-white text-colorbase m-0 d-flex justify-content-between align-items-center" header-tag="h5">
                <span v-html="$t(`kpi.bedsPerStar.header`)" />
                <font-awesome-icon icon="bed" />
              </b-card-header>
              <b-card-body>
                <div class="my-2" v-html="$t(`kpi.bedsPerStar.description`)" />
                <b-row cols="2" no-gutters>
                  <b-col v-if="bedsPerStar.values.length">
                    <div class="value-container my-2" v-for="val in bedsPerStar.values" :key="val.type">
                      <div class="font-weight-bold kpi-label-header" v-html="translateLabels('bedsPerStar', val.type)" />
                      <div>
                        <span class="font-weight-bold" v-html="val.count" />
                        <span class="small ml-1" v-html="$tc('utils.beds', val.count)" />
                      </div>
                    </div>
                  </b-col>
                  <b-col md="12" lg="4">
                    <apexchart v-if="bedsPerStar.chart" type="pie" :options="bedsPerStar.chart.options" :series="bedsPerStar.chart.series" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col> -->
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet"
import DashboardMixin from "@/mixins/api/dashboard"
import AuthMixin from "@/mixins/api/auth"
import ItemsList from '@/components/utils/ItemsList'
export default {
  name: "Dashboard",
  components: {
    ItemsList
  },
  mixins: [DashboardMixin, AuthMixin],
  data() {
    return {
        bedsPerStar: { values: [], chart: null },
      sections: {
        options: null,
        default: 0,
      },
      zoom: 9.5,
      center: latLng(46.2307967, 7.7220283),
      bounds: {
        _northEast: latLng(46.92588289494367, 9.014282226562502),
        _southWest: latLng(45.77135470445038, 6.231994628906251)
      },
      colors: {
        members: '#DA291C',
        notMembers: 'cornflowerblue',
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: null,
      currentCenter: null,
      currentBounds: null,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      showMap: true,
      searchQuery: null,
      noLocationOnly: false,
    }
  },
  computed: {
    longLatObjects() {
      const results = this.searchedObjects.filter(o => o.lat != null && o.long != null)
      return results
    },
    searchedObjects() {
      let results = this.objects
      if (this.noLocationOnly) return results = results.filter(o => o.lat == null || o.long == null)
      if (this.searchQuery == null) return results
      return results.filter(o => o.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
    nbMembers() {
      return this.searchedObjects.filter(o => o.idMember != null && o.lat != null && o.long != null).length
    },
  },
  mounted() {
    /*if (this.objects == null || !this.objects.length || this.objects.length == 0)
      this.refreshAll()
    else this.refreshKPIData()*/
    this.getSections();
    this.refreshAll();
    this.getaccommodationDetails();
  },
  methods: {
    getFlagColor(flagTxt) {
      switch (flagTxt) {
        case "ok": return 'lightgreen'
        default: return 'darkorange'
      }
    },
    checkIfBothAddressAndCity(obj) {
      if (obj.city != null && obj.address != null && obj.city != '' && obj.address != '')
        return ', '
      return ''
    },
    changeSection(optionValue) {
      this.sections.default = optionValue
      this.refreshAll();
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    boundsUpdate(bounds) {
      this.currentBounds = bounds
    },
  },
}
</script>

<style lang="scss">
#dashboard {
  & ::-webkit-scrollbar {
    width: 6px;
  }

  & ::-webkit-scrollbar-track {
    background: theme-color-level("custom", -10);
  }

  & ::-webkit-scrollbar-thumb {
    background: theme-color("custom");
  }

  & ::-webkit-scrollbar-thumb:hover {
    background: theme-color-level("custom", 4);
  }

  .custom-popup {

    p,
    h6 {
      margin: 0;
    }

    .flag-container {
      font-size: 1em;
      text-align: center;
      color: white;
      border-radius: 5px;
    }
  }

  #kpis {
    .value-container {
      line-height: 1.2;
    }

    .chart-container {
      width: 33% !important;
      flex-grow: 1;
    }

    .kpi-label-header {
      line-height: 1;
    }
  }

  .collapse.show {
    transition: width 0.5s, height 0.5s, transform 0.5s;
  }

  .marker-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background-color: black;
  }
}

#viewNavigator {
  width: 100%;
  text-align: center;
  margin: 1em 0;

  .navigator-btn {
    padding: 0.375rem 0.75rem;
    color: $colorbase-grey;
    border: 2px solid $colorbase-grey;
    border-radius: 0 50px 50px 0;
    background-color: $colorbase-lightergrey;
    width: 15em !important;
    min-width: 15em !important;
    cursor: pointer;

    &:first-child {
      border-radius: 50px 0 0 50px;
      border-right: 1px solid $colorbase-grey;
    }

    &:last-child {
      border-left: 1px solid $colorbase-grey;
    }

    &.active {
      color: $colorbase;
      background-color: $colorbase-lightest;
      border-color: $colorbase;
    }
  }
}
</style>